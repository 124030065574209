import {createSlice, PayloadAction} from '@reduxjs/toolkit'

// Define a type for the slice state
interface AuthState {
    Key: string
}

// Define the initial state using that type
const initialState: AuthState = {
    Key: ''
}

export const authSlice = createSlice({
    name: 'basket',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setKey: (state, action: PayloadAction<any>) => {
            state.Key = action.payload
        },
    },
})

export const {setKey} = authSlice.actions

// Other code such as selectors can use the imported `RootState` type
//export const selectCount = (state: RootState) => state.counter.value

export default authSlice.reducer

import registrationReducer from './registration/registrationSlice';
import memberReducer from './member/memberSlice';
import basketReducer from './basket/basketSlice';
import bookmarkReducer from './bookmark/bookmarkSlice';
import authReducer from './auth/authSlice';
import cookieReducer from './cookies/cookieSlice';
import rootSaga from "../sagas";
import {combineReducers, configureStore} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import storage from 'redux-persist/lib/storage'
import {persistReducer, persistStore} from 'redux-persist'

export default () => {
    const persistConfig = {
        key: 'rootNew',
        storage,
    }

    const rootReducer = combineReducers({
        registration: registrationReducer,
        basket: basketReducer,
        bookmark: bookmarkReducer,
        member: memberReducer,
        auth: authReducer,
        cookie: cookieReducer
    })

    const persistedReducer = persistReducer(persistConfig, rootReducer)

    let sagaMiddleware = createSagaMiddleware();

    const store = configureStore({
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) => [...getDefaultMiddleware({thunk: false}), sagaMiddleware]
    });

    let persistor = persistStore(store)

    sagaMiddleware.run(rootSaga);
    return {store, persistor}
}

import {all} from 'redux-saga/effects';

export default function* root() {
    yield all([
        /**
         * @see https://redux-saga.js.org/docs/basics/UsingSagaHelpers.html
         */
        // Call `fetchUser()` when a `FETCH_USER` action is triggered
    ]);
}

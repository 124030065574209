import {createSlice, PayloadAction} from '@reduxjs/toolkit'

// Define a type for the slice state
interface BasketState {
    FunctionalCookies: boolean,
    PerformanceCookies: boolean,
    TargetingCookies: boolean,
    ShowCookieOverlay: boolean
}

// Define the initial state using that type
const initialState: BasketState = {
    FunctionalCookies: false,
    PerformanceCookies: false,
    TargetingCookies: false,
    ShowCookieOverlay: true
}

export const cookieSlice = createSlice({
    name: 'cookie',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setFunctionalCookies: (state, action: PayloadAction<any>) => {
            state.FunctionalCookies = action.payload
        },
        setPerformanceCookies: (state, action: PayloadAction<any>) => {
            state.PerformanceCookies = action.payload
        },
        setTargetingCookies: (state, action: PayloadAction<any>) => {
            state.TargetingCookies = action.payload
        },
        setShowCookieOverlay: (state, action: PayloadAction<any>) => {
            state.ShowCookieOverlay = action.payload
        },
    },
})

export const {
    setFunctionalCookies,
    setPerformanceCookies,
    setTargetingCookies,
    setShowCookieOverlay
} = cookieSlice.actions

// Other code such as selectors can use the imported `RootState` type
//export const selectCount = (state: RootState) => state.counter.value

export default cookieSlice.reducer

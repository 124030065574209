import {createSlice, PayloadAction} from '@reduxjs/toolkit'

// Define a type for the slice state
interface RegistrationState {
    AGBs: string,
    ROW: string,
    CompanyName: string,
    Phone: string,
    Email: string,
    Web: string,
    UID: string,
    fotos: Array<any>,
    headerImage: Array<any>,
    logo: Array<any>,
    shortDesc: string,
    longDesc: string,
    Street: string,
    StreetNr: string,
    ZipCode: string,
    Location: string,
    Country: string,
    Category: Array<any>,
    Privacy: boolean,
    Images: Array<any>,
    maxFinishedStep: number,
    PlanID: number,
    CategorySuggestion: string,
    AddSuggestion: boolean,
    IdDocument: Array<any>
}

// Define the initial state using that type
const initialState: RegistrationState = {
    AGBs: '',
    ROW: '',
    CompanyName: '',
    Phone: '',
    Email: '',
    Web: '',
    UID: '',
    fotos: [],
    headerImage: [],
    logo: [],
    shortDesc: '',
    longDesc: '',
    Street: '',
    StreetNr: '',
    ZipCode: '',
    Location: '',
    Country: '',
    Category: [],
    Privacy: false,
    Images: [],
    maxFinishedStep: 0,
    PlanID: 0,
    IdDocument: [],
    CategorySuggestion: '',
    AddSuggestion: false
}

export const registrationSlice = createSlice({
    name: 'registration',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setAGBs: (state, action: PayloadAction<any>) => {
            state.AGBs = action.payload
        },
        setROW: (state, action: PayloadAction<any>) => {
            state.ROW = action.payload
        },
        setCompanyName: (state, action: PayloadAction<any>) => {
            state.CompanyName = action.payload
        },
        setPhone: (state, action: PayloadAction<any>) => {
            state.Phone = action.payload
        },
        setEmail: (state, action: PayloadAction<any>) => {
            state.Email = action.payload
        },
        setFotos: (state, action: PayloadAction<any>) => {
            state.fotos = action.payload
        },
        setHeaderImage: (state, action: PayloadAction<any>) => {
            state.headerImage = action.payload
        },
        setLogo: (state, action: PayloadAction<any>) => {
            state.logo = action.payload
        },
        setWeb: (state, action: PayloadAction<any>) => {
            state.Web = action.payload
        },
        setUID: (state, action: PayloadAction<any>) => {
            state.UID = action.payload
        },
        setShortDesc: (state, action: PayloadAction<any>) => {
            state.shortDesc = action.payload
        },
        setLongDesc: (state, action: PayloadAction<any>) => {
            state.longDesc = action.payload
        },
        setStreet: (state, action: PayloadAction<any>) => {
            state.Street = action.payload
        },
        setStreetNr: (state, action: PayloadAction<any>) => {
            state.StreetNr = action.payload
        },
        setZipCode: (state, action: PayloadAction<any>) => {
            state.ZipCode = action.payload
        },
        setLocation: (state, action: PayloadAction<any>) => {
            state.Location = action.payload
        },
        setCountry: (state, action: PayloadAction<any>) => {
            state.Country = action.payload
        },
        addImage: (state, action: PayloadAction<any>) => {
            state.Images.push(action.payload)
        },
        setPrivacy: (state, action: PayloadAction<any>) => {
            state.Privacy = action.payload
        },
        setCategory: (state, action: PayloadAction<any>) => {
            state.Category = action.payload
        },
        setPlanID: (state, action: PayloadAction<any>) => {
            state.PlanID = action.payload
        },
        setMaxFinishedStep: (state, action: PayloadAction<any>) => {
            state.maxFinishedStep = action.payload
        },
        setIdDocument: (state, action: PayloadAction<any>) => {
            state.IdDocument = action.payload
        },
        resetRegistration: (state, action: PayloadAction<any>) => {
            state.AGBs = ''
            state.ROW =''
            state.CompanyName = ''
            state.Phone =''
            state.Email = ''
            state.Web = ''
            state.UID = ''
            state.fotos = []
            state.headerImage = []
            state.logo = []
            state.shortDesc = ''
            state.longDesc = ''
            state.Street = ''
            state.StreetNr = ''
            state.ZipCode = ''
            state.Location = ''
            state.Country = ''
            state.Category = []
            state.Privacy =  false
            state.Images = []
            state.IdDocument = []
            state.maxFinishedStep =  0
            state.PlanID =  0
        },
        setCategorySuggestion: (state, action: PayloadAction<any>) => {
            state.CategorySuggestion = action.payload
        },
        setAddSuggestion: (state, action: PayloadAction<any>) => {
            state.AddSuggestion = action.payload
        },
    },
})

export const {
    setAGBs,
    setROW,
    setCompanyName,
    setPhone,
    setEmail,
    setFotos,
    setLogo,
    setHeaderImage,
    setWeb,
    setUID,
    setShortDesc,
    setLongDesc,
    setStreet,
    setStreetNr,
    setZipCode,
    setLocation,
    setCountry,
    addImage,
    setPrivacy,
    setCategory,
    setPlanID,
    setMaxFinishedStep,
    resetRegistration,
    setCategorySuggestion,
    setAddSuggestion,
    setIdDocument
} = registrationSlice.actions

// Other code such as selectors can use the imported `RootState` type
//export const selectCount = (state: RootState) => state.counter.value

export default registrationSlice.reducer

import {createSlice, PayloadAction} from '@reduxjs/toolkit'

// Define a type for the slice state
interface BookmarkState {
    BookmarkID: number,
    BookmarkAmount: number
}

// Define the initial state using that type
const initialState: BookmarkState = {
    BookmarkID: 0,
    BookmarkAmount: 0
}

export const bookmarkSlice = createSlice({
    name: 'bookmark',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setBookmarkID: (state, action: PayloadAction<any>) => {
            state.BookmarkID = action.payload
        },
        setBookmarkAmount: (state, action: PayloadAction<any>) => {
            state.BookmarkAmount = action.payload
        },
    },
})

export const {
    setBookmarkID,
    setBookmarkAmount
} = bookmarkSlice.actions

// Other code such as selectors can use the imported `RootState` type
//export const selectCount = (state: RootState) => state.counter.value

export default bookmarkSlice.reducer

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-page-types-content-page-content-page-tsx": () => import("./../../../src/PageTypes/ContentPage/ContentPage.tsx" /* webpackChunkName: "component---src-page-types-content-page-content-page-tsx" */),
  "component---src-page-types-product-page-product-page-tsx": () => import("./../../../src/PageTypes/ProductPage/ProductPage.tsx" /* webpackChunkName: "component---src-page-types-product-page-product-page-tsx" */),
  "component---src-page-types-vendor-page-vendor-page-tsx": () => import("./../../../src/PageTypes/VendorPage/VendorPage.tsx" /* webpackChunkName: "component---src-page-types-vendor-page-vendor-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-agb-index-tsx": () => import("./../../../src/pages/agb/index.tsx" /* webpackChunkName: "component---src-pages-agb-index-tsx" */),
  "component---src-pages-basket-index-tsx": () => import("./../../../src/pages/basket/index.tsx" /* webpackChunkName: "component---src-pages-basket-index-tsx" */),
  "component---src-pages-checkout-index-tsx": () => import("./../../../src/pages/checkout/index.tsx" /* webpackChunkName: "component---src-pages-checkout-index-tsx" */),
  "component---src-pages-checkout-success-index-tsx": () => import("./../../../src/pages/checkoutSuccess/index.tsx" /* webpackChunkName: "component---src-pages-checkout-success-index-tsx" */),
  "component---src-pages-datenschutz-index-tsx": () => import("./../../../src/pages/datenschutz/index.tsx" /* webpackChunkName: "component---src-pages-datenschutz-index-tsx" */),
  "component---src-pages-favorites-index-tsx": () => import("./../../../src/pages/favorites/index.tsx" /* webpackChunkName: "component---src-pages-favorites-index-tsx" */),
  "component---src-pages-haendleruebersicht-index-tsx": () => import("./../../../src/pages/haendleruebersicht/index.tsx" /* webpackChunkName: "component---src-pages-haendleruebersicht-index-tsx" */),
  "component---src-pages-impressum-index-tsx": () => import("./../../../src/pages/impressum/index.tsx" /* webpackChunkName: "component---src-pages-impressum-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-index-tsx": () => import("./../../../src/pages/kontakt/index.tsx" /* webpackChunkName: "component---src-pages-kontakt-index-tsx" */),
  "component---src-pages-landingpage-components-accordion-accordion-tsx": () => import("./../../../src/pages/landingpage/components/Accordion/Accordion.tsx" /* webpackChunkName: "component---src-pages-landingpage-components-accordion-accordion-tsx" */),
  "component---src-pages-landingpage-components-content-content-tsx": () => import("./../../../src/pages/landingpage/components/Content/Content.tsx" /* webpackChunkName: "component---src-pages-landingpage-components-content-content-tsx" */),
  "component---src-pages-landingpage-components-detail-table-detail-table-tsx": () => import("./../../../src/pages/landingpage/components/DetailTable/DetailTable.tsx" /* webpackChunkName: "component---src-pages-landingpage-components-detail-table-detail-table-tsx" */),
  "component---src-pages-landingpage-components-header-header-tsx": () => import("./../../../src/pages/landingpage/components/Header/Header.tsx" /* webpackChunkName: "component---src-pages-landingpage-components-header-header-tsx" */),
  "component---src-pages-landingpage-components-planstable-plans-table-tsx": () => import("./../../../src/pages/landingpage/components/Planstable/PlansTable.tsx" /* webpackChunkName: "component---src-pages-landingpage-components-planstable-plans-table-tsx" */),
  "component---src-pages-landingpage-components-products-table-products-table-tsx": () => import("./../../../src/pages/landingpage/components/ProductsTable/ProductsTable.tsx" /* webpackChunkName: "component---src-pages-landingpage-components-products-table-products-table-tsx" */),
  "component---src-pages-landingpage-components-small-list-small-list-tsx": () => import("./../../../src/pages/landingpage/components/SmallList/SmallList.tsx" /* webpackChunkName: "component---src-pages-landingpage-components-small-list-small-list-tsx" */),
  "component---src-pages-landingpage-components-table-content-table-tsx": () => import("./../../../src/pages/landingpage/components/TableContent/Table.tsx" /* webpackChunkName: "component---src-pages-landingpage-components-table-content-table-tsx" */),
  "component---src-pages-landingpage-components-teaser-teaser-tsx": () => import("./../../../src/pages/landingpage/components/Teaser/Teaser.tsx" /* webpackChunkName: "component---src-pages-landingpage-components-teaser-teaser-tsx" */),
  "component---src-pages-landingpage-components-two-sided-teaser-two-sided-teaser-tsx": () => import("./../../../src/pages/landingpage/components/TwoSidedTeaser/TwoSidedTeaser.tsx" /* webpackChunkName: "component---src-pages-landingpage-components-two-sided-teaser-two-sided-teaser-tsx" */),
  "component---src-pages-landingpage-components-video-teaser-video-teaser-tsx": () => import("./../../../src/pages/landingpage/components/VideoTeaser/VideoTeaser.tsx" /* webpackChunkName: "component---src-pages-landingpage-components-video-teaser-video-teaser-tsx" */),
  "component---src-pages-landingpage-index-tsx": () => import("./../../../src/pages/landingpage/index.tsx" /* webpackChunkName: "component---src-pages-landingpage-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-logout-index-tsx": () => import("./../../../src/pages/logout/index.tsx" /* webpackChunkName: "component---src-pages-logout-index-tsx" */),
  "component---src-pages-marktplatz-index-tsx": () => import("./../../../src/pages/marktplatz/index.tsx" /* webpackChunkName: "component---src-pages-marktplatz-index-tsx" */),
  "component---src-pages-marktplatz-marketplace-filter-marketplace-filter-tsx": () => import("./../../../src/pages/marktplatz/MarketplaceFilter/MarketplaceFilter.tsx" /* webpackChunkName: "component---src-pages-marktplatz-marketplace-filter-marketplace-filter-tsx" */),
  "component---src-pages-modify-product-index-tsx": () => import("./../../../src/pages/modifyProduct/index.tsx" /* webpackChunkName: "component---src-pages-modify-product-index-tsx" */),
  "component---src-pages-modify-product-product-group-row-product-group-row-tsx": () => import("./../../../src/pages/modifyProduct/ProductGroupRow/ProductGroupRow.tsx" /* webpackChunkName: "component---src-pages-modify-product-product-group-row-product-group-row-tsx" */),
  "component---src-pages-modify-product-scaled-price-scaled-price-tsx": () => import("./../../../src/pages/modifyProduct/ScaledPrice/ScaledPrice.tsx" /* webpackChunkName: "component---src-pages-modify-product-scaled-price-scaled-price-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("./../../../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */),
  "component---src-pages-register-index-tsx": () => import("./../../../src/pages/register/index.tsx" /* webpackChunkName: "component---src-pages-register-index-tsx" */),
  "component---src-pages-register-vendor-index-tsx": () => import("./../../../src/pages/register/vendor/index.tsx" /* webpackChunkName: "component---src-pages-register-vendor-index-tsx" */),
  "component---src-pages-registration-success-index-tsx": () => import("./../../../src/pages/registrationSuccess/index.tsx" /* webpackChunkName: "component---src-pages-registration-success-index-tsx" */),
  "component---src-pages-resetpw-index-tsx": () => import("./../../../src/pages/resetpw/index.tsx" /* webpackChunkName: "component---src-pages-resetpw-index-tsx" */),
  "component---src-pages-revocation-index-tsx": () => import("./../../../src/pages/revocation/index.tsx" /* webpackChunkName: "component---src-pages-revocation-index-tsx" */),
  "component---src-pages-set-iban-iban-form-tsx": () => import("./../../../src/pages/setIBAN/IBANForm.tsx" /* webpackChunkName: "component---src-pages-set-iban-iban-form-tsx" */),
  "component---src-pages-set-iban-index-tsx": () => import("./../../../src/pages/setIBAN/index.tsx" /* webpackChunkName: "component---src-pages-set-iban-index-tsx" */),
  "component---src-pages-set-iban-payment-setup-form-tsx": () => import("./../../../src/pages/setIBAN/PaymentSetupForm.tsx" /* webpackChunkName: "component---src-pages-set-iban-payment-setup-form-tsx" */),
  "component---src-pages-suche-index-tsx": () => import("./../../../src/pages/suche/index.tsx" /* webpackChunkName: "component---src-pages-suche-index-tsx" */),
  "component---src-pages-user-index-tsx": () => import("./../../../src/pages/user/index.tsx" /* webpackChunkName: "component---src-pages-user-index-tsx" */),
  "component---src-pages-user-vendor-tsx": () => import("./../../../src/pages/user/vendor.tsx" /* webpackChunkName: "component---src-pages-user-vendor-tsx" */),
  "component---src-pages-widerrufsrecht-index-tsx": () => import("./../../../src/pages/widerrufsrecht/index.tsx" /* webpackChunkName: "component---src-pages-widerrufsrecht-index-tsx" */),
  "component---src-pages-wiederrufsrecht-index-tsx": () => import("./../../../src/pages/wiederrufsrecht/index.tsx" /* webpackChunkName: "component---src-pages-wiederrufsrecht-index-tsx" */)
}


import {createSlice, PayloadAction} from '@reduxjs/toolkit'

// Define a type for the slice state
interface MemberState {
    MemberID: any,
    Member: Object,
    VendorID: any,
    LastEditedProductID: number | null | string,
}

// Define the initial state using that type
const initialState: MemberState = {
    MemberID: null,
    Member: {},
    VendorID: null,
    LastEditedProductID: null,
}

export const memberSlice = createSlice({
    name: 'member',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setMemberID: (state, action: PayloadAction<any>) => {
            state.MemberID = action.payload
        },
        setMember: (state, action: PayloadAction<any>) => {
            state.Member = action.payload
        },
        setVendorID: (state, action: PayloadAction<any>) => {
            state.VendorID = action.payload
        },
        setLastEditedProductID: (state, action: PayloadAction<any>) => {
            state.LastEditedProductID = action.payload
        },
    },
})

export const {
    setMemberID,
    setMember,
    setVendorID,
    setLastEditedProductID,
} = memberSlice.actions

// Other code such as selectors can use the imported `RootState` type
//export const selectCount = (state: RootState) => state.counter.value

export default memberSlice.reducer

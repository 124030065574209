import {createSlice, PayloadAction} from '@reduxjs/toolkit'

// Define a type for the slice state
interface BasketState {
    BasketID: number,
    BasketAmount: number
}

// Define the initial state using that type
const initialState: BasketState = {
    BasketID: 0,
    BasketAmount: 0
}

export const basketSlice = createSlice({
    name: 'basket',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setBasketID: (state, action: PayloadAction<any>) => {
            state.BasketID = action.payload
        },
        setBasketAmount: (state, action: PayloadAction<any>) => {
            state.BasketAmount = action.payload
        },
    },
})

export const {
    setBasketID,
    setBasketAmount
} = basketSlice.actions

// Other code such as selectors can use the imported `RootState` type
//export const selectCount = (state: RootState) => state.counter.value

export default basketSlice.reducer
